

.siginContainer{
    margin-top: 50px;
}


a.bodyLinks:link  {
    color: #2f4a74;
}

a.bodyLinks:active {
    color: #2f4a74;
}

a.bodyLinks:hover {
    color: #2f4a74;
    text-decoration: none;
}

a.bodyLinks:visited {
    color: #2f4a74;
}


.rowForm{
    margin-top: 20px;
}
@media (max-width : 500px) {
    .siginContainer{
    margin-top: 50px;
    }
    
   }