.linkMenu {
    color: #2f4a74;
    font-family: Open sans, arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1;
    font-size: .75rem;
    font-weight: 600;
    margin-right: 30px;
}

.titleSession {
    color: #19534C;
    font-family: Open sans, arial, sans-serif;
    letter-spacing: 3;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-top: 40px;
}

.titleSessionProduct {
    color: #2f4a74;
    font-family: Open sans, arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 3;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 40px;
}

.productsIcon {
    color: #0B502F;
    font-family: Open sans, arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 3;
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
    margin-top: 40px;
}

.productsText {
    color: white;
    font-family: Open sans, arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 3;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
}

.gradientGreen {

    background: rgb(15, 77, 30);
    background: linear-gradient(180deg, rgba(15, 77, 30, 1) 0%, rgba(2, 17, 20, 1) 24%);
}

a.rodape:link {
    color: white;
}

a.rodape:active {
    color: white;
}

a.rodape:hover {
    color: white;
    text-decoration: none;
}

a.rodape:visited {
    color: white;
}

a.header:link {
    color: #2f4a74;
}

a.header:active {
    color: #2f4a74;
}

a.header:hover {
    color: #2f4a74;
    text-decoration: none;
}

a.header:visited {
    color: #2f4a74;
}

.logo {
    /* color: linear-gradient(to right, #009246 0, #ce2b37 66%); */
    font-size: 30px;
    font-weight: 1000;
    color: black;
    /* background: linear-gradient(to right, #009246 33%, #fff 50%, #ce2b37 66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

a.logo:link {
    color: #2f4a74;
}

a.logo:active {
    color: #2f4a74;
}

a.logo:hover {
    color: #2f4a74;
    text-decoration: none;
}

a.logo:visited {
    color: #2f4a74;
}

.divBanner {
    position: absolute;
    bottom: 15px;
    background-color: black;
    padding: 20px;
    opacity: 0.5;
    width: 100%;
    height: 150px;
    z-Index: -5;
}

.divBannerExperiencia {

    position: absolute;
    top: 0px;
    background-color: black;
    padding: 20px;
    opacity: 0.5;
    width: 110%;
    left: -5%;
    height: 100%;
    z-Index: -5;

}

.imageExperiencia {
    height: 450px !important;
    width: 100% !important;
    object-fit: cover !important;
}

@media (max-width : 1440px) {
    .divBanner {
        position: absolute;
        bottom: 15px;
        background-color: black;
        padding: 20px;
        opacity: 0.5;
        width: 105%;
        height: 100px;
        z-Index: -5;
    }


}


@media (max-width : 1300px) {
    .divBanner {
        position: absolute;
        bottom: 15px;
        background-color: black;
        padding: 20px;
        opacity: 0.5;
        width: 105%;
        height: 140px;
        z-Index: -5;
    }

    .imageExperiencia {
        height: 650px !important;
        width: 100% !important;
        object-fit: cover !important;
    }

}


@media (max-width : 768px) {
    .divBanner {
        position: absolute;
        bottom: 15px;
        background-color: black;
        padding: 20px;
        opacity: 0.5;
        width: 105%;
        height: 160px;
        z-Index: -5;
    }

    .logo {
        color: #2f4a74;
        font-size: 20px;
        font-weight: 1000
    }

    .imageExperiencia {
        height: 650px !important;
        width: 100% !important;
        object-fit: cover !important;
    }

}

@media (max-width : 716px) {
    .divBanner {
        position: absolute;
        bottom: 15px;
        background-color: black;
        padding: 20px;
        opacity: 0.5;
        width: 105%;
        height: 250px;
        z-Index: -5;
    }

    .logo {
        color: #2f4a74;
        font-size: 20px;
        font-weight: 1000
    }

    .imageExperiencia {
        height: 750px !important;
        width: 100% !important;
        object-fit: cover !important;
    }

}


@media (max-width : 380px) {
    .divBanner {
        position: absolute;
        bottom: 15px;
        background-color: black;
        padding: 20px;
        opacity: 0.5;
        width: 105%;
        height: 300px;
        z-Index: -5;
    }

    .divBannerExperiencia {

        position: absolute;
        top: 0px;
        background-color: black;
        padding: 20px;
        opacity: 0.5;
        width: 110%;
        left: -5%;
        height: 100%;
        z-Index: -5;

    }

    .imageExperiencia {
        height: 750px !important;
        width: 100% !important;
        object-fit: cover !important;
    }

    .logo {
        color: #2f4a74;
        font-size: 20px;
        font-weight: 1000
    }

}